<template>
    <nav class="main-header navbar navbar-expand-md navbar-dark navbar-navy fixed-top">
        <div class="container">
            <a href="/" class="navbar-brand animation__shake">
                <img src="../../src/assets/img/logo.png" alt="AdminLTE Logo" class="brand-image elevation-3 animation__shake"
                    style="opacity: .8;height: 30px;">
                <span class="brand-text font-weight-light">&nbsp;SiPetarung</span>
            </a>

            <button class="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse order-3" id="navbarCollapse">
                <!-- Left navbar links -->
                <ul class="navbar-nav">
                    <li class="nav-item" id="Beranda">
                        <a href="/" class="nav-link">Beranda</a>
                    </li>
                    <li class="nav-item" id="Perencanaan">
                        <a href="/Perencanaan" class="nav-link">Perencanaan</a>
                    </li>
                    <li class="nav-item" id="Pemanfaatan">
                        <a href="/Pemanfaatan" class="nav-link">Pemanfaatan</a>
                    </li>
                    <li class="nav-item" id="Pengendalian">
                        <a href="/Pengendalian" class="nav-link">Pengendalian</a>
                    </li>
                    <li class="nav-item dropdown" id="Pengajuan">
                        <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false" class="nav-link dropdown-toggle">Pengajuan</a>
                        <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu border-0 shadow">
                            <li><a href="/Pengeringan" class="dropdown-item" id="Pengeringan">Pengajuan PKKPR </a></li>
                            <li><a href="/KTTR" class="dropdown-item" id="KTTR">Pengajuan KTTR</a></li>
                            <li><a href="/Pengajuan" class="dropdown-item" id="ProgresPengajuan">Progres Pengajuan</a></li>
                        </ul>
                    </li>
                    <li class="nav-item" id="Masuk">
                        <a href="/Masuk" class="nav-link">Masuk</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script></script>

<style></style>