<template>
    <nav class="main-header navbar navbar-expand-md navbar-dark navbar-navy fixed-top">
        <div class="container">
            <a href="/DashboardAdmin" class="navbar-brand animation__wobble">
                <img src="../../../../src/assets/img/logo.png" alt="AdminLTE Logo"
                    class="brand-image elevation-3 animation__wobble" style="opacity: .8;height: 30px;">
                <span class="brand-text font-weight-light">&nbsp;SiPetarung</span>
            </a>

            <button class="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse order-3" id="navbarCollapse">
                <!-- Left navbar links -->
                <ul class="navbar-nav">
                    <li class="nav-item" id="BerandaAdmin">
                        <a href="/DashboardAdmin" class="nav-link">Beranda</a>
                    </li>
                    <li class="nav-item dropdown" id="DataPengajuanAdmin">
                        <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false" class="nav-link dropdown-toggle">Data Pengajuan</a>
                        <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu border-0 shadow">
                            <li><a href="/PengeringanAdmin" class="dropdown-item" id="PengeringanAdmin">Pengajuan PKKPR</a></li>
                            <li><a href="/KTTRAdmin" class="dropdown-item" id="KTTRAdmin">Pengajuan KTTR</a></li>
                        </ul>
                    </li>
                    <li class="nav-item" id="PengaturanPengguna">
                        <a href="/PengaturanPengguna" class="nav-link">Pengaturan Pengguna</a>
                    </li>
                </ul>
            </div>

            <!-- Right navbar links -->
            <ul class="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
                <li class="nav-item" id="Keluar">
                    <a href="#" class="nav-link" v-on:click="logoutAct">Keluar Akun {{ datauser }}</a>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import axios from "axios";
import swal from 'sweetalert2';
export default {
    components: {
        swal
    },
    data() {
        return {
            datauser: '',
        }
    },
    methods: {
        getUser() {
            this.datauser = JSON.parse(localStorage.getItem("usistem"));
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                localStorage.removeItem('tokensistem');
                localStorage.removeItem('usistem');
                localStorage.removeItem('lsistem');
                localStorage.removeItem('tsistem');
            }
        },
        async logoutAct() {
            swal.fire({
                title: "Konfirmasi",
                text: "Yakin Hendak Keluar Aplikasi?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Batal",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Keluar"
            }).then((result) => {
                if (result.isConfirmed) {
                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                    });
                    mainAPI.get("petarung-keluar").then(
                        Response => {
                            localStorage.removeItem('tokensistem');
                            localStorage.removeItem('usistem');
                            localStorage.removeItem('lsistem');
                            localStorage.removeItem('tsistem');
                            window.location.replace("/Masuk");
                        }
                    ).catch(
                        error => {
                            localStorage.removeItem('tokensistem');
                            localStorage.removeItem('usistem');
                            localStorage.removeItem('lsistem');
                            localStorage.removeItem('tsistem');
                            window.location.replace("/Masuk");
                        }
                    )
                }
            });

            return false;
        }
    },
    mounted() {
        this.getOtentifikasi();
        this.getUser();
        if ((localStorage.getItem("tokensistem") == null)) {
            window.location.replace("/");
        };
    },
}
</script>

<style></style>