import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Beranda',
    component: () => import('../../components/views/Publik/Beranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Perencanaan',
    name: 'Perencanaan',
    component: () => import('../../components/views/Publik/Perencanaan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Pemanfaatan',
    name: 'Pemanfaatan',
    component: () => import('../../components/views/Publik/Pemanfaatan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Pengendalian',
    name: 'Pengendalian',
    component: () => import('../../components/views/Publik/Pengendalian.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Pengeringan',
    name: 'Pengeringan',
    component: () => import('../../components/views/Publik/Pengeringan/ProsesPengeringan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Pembangunan',
    name: 'Pembangunan',
    component: () => import('../../components/views/Publik/Pembangunan/ProsesPembangunan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/KTTR',
    name: 'KTTR',
    component: () => import('../../components/views/Publik/Pertimbangan/ProsesPertimbangan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Pengajuan',
    name: 'Pengajuan',
    component: () => import('../../components/views/Publik/Pengajuan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Masuk',
    name: 'Masuk',
    component: () => import('../../components/views/Publik/Masuk.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DashboardAdmin',
    name: 'DashboardAdmin',
    component: () => import('../../components/views/Admin/Dashboard/AdminBeranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/PengeringanAdmin',
    name: 'PengeringanAdmin',
    component: () => import('../../components/views/Admin/Pengeringan/Pengeringan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesPengeringanAdmin/:id',
    name: 'ProsesPengeringanAdmin',
    props: true,
    component: () => import('../../components/views/Admin/Pengeringan/ProsesPengeringan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/PembangunanAdmin',
    name: 'PembangunanAdmin',
    component: () => import('../../components/views/Admin/Pembangunan/Pembangunan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesPembangunanAdmin/:id',
    name: 'ProsesPembangunanAdmin',
    props: true,
    component: () => import('../../components/views/Admin/Pembangunan/ProsesPembangunan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/KTTRAdmin',
    name: 'KTTRAdmin',
    component: () => import('../../components/views/Admin/Pertimbangan/Pertimbangan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesPertimbanganAdmin/:id',
    name: 'ProsesPertimbanganAdmin',
    props: true,
    component: () => import('../../components/views/Admin/Pertimbangan/ProsesPertimbangan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/PengaturanPengguna',
    name: 'PengaturanPengguna',
    component: () => import('../../components/views/Admin/PengaturanPengguna/PengaturanPengguna.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesPengaturanPengguna/:id',
    name: 'ProsesPengaturanPengguna',
    props: true,
    component: () => import('../../components/views/Admin/PengaturanPengguna/ProsesPengaturanPengguna.vue'),
    meta: {
      transition: "zoom",
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  routes: routes,
  linkActiveClass: 'active',
})

export default router;
