<template>
    <Navbar v-if="(token == null)"></Navbar>
    <NavbarAdmin  v-if="token != null"></NavbarAdmin>
    <router-view></router-view>
    <Footer></Footer>
</template>

<script>
import Navbar from "../src/components/NavBarPublic.vue";
import NavbarAdmin from "../src/components/views/Admin/NavBarAdmin.vue";
import Footer from "../src/components/FooterPublic.vue";


export default {
    data() {
        return {
            tahun: new Date().getFullYear(),
            token: localStorage.getItem("tokensistem"),
            usistem: localStorage.getItem("usistem"),
            lsistem: localStorage.getItem("lsistem"),
        };
    },
    name: 'App',
    components: {
        Navbar, NavbarAdmin, Footer,
    },
    mounted() {
        document.title = "Sipetarung Kudus";
    },

};
</script>

<style></style>
