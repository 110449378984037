<template>
    <footer class="main-footer" style="height: 50px !important;">
        <!-- To the right -->
        <div class="float-right d-none d-sm-inline">
            Versi 2.0.0 - Sipetarung Kabupaten Kudus
        </div>
        <!-- Default to the left -->
        <strong>Copyright &copy; {{ tahun }} <a href="http://pupr.kuduskab.go.id/" target="_blank">DPUPR KAB. KUDUS</a>.</strong> All rights reserved.
    </footer>
</template>

<script>
export default {
  data() {
    return {
        tahun : new Date().getFullYear(),
    }
  },
}
</script>

<style></style>